import { Observable, throwError } from 'rxjs';
import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { SearchCriteria } from 'app_code/app/shared/interfaces/comment-search-criteria';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { SearchResult } from 'app_code/app/shared/interfaces/search-result';
import { AuthService } from 'app_code/app/shared/services/auth/auth.service';
import { MapQueryParams } from '../../map/model/map-query-params-model';
import { StoreDataService } from 'app_code/app/shared/services/store/store-data.service';
import { MapService } from '../../map/services/map.service';

@Injectable()
export class SearchMenuService {

    //we have to have one to filter values, second to emait once value is fitlered
    private searchCriteria = new Subject<SearchCriteria>();
    private urlParamsSubject = new Subject<MapQueryParams>();
    private clearSerachMenuSubject = new Subject<any>();

    constructor(
        private authHttp: HttpClient, 
        private authService: AuthService,
    ) {}

    public sendSearchCriteria(searchCriteria: SearchCriteria) {
        this.searchCriteria.next(searchCriteria);
    }

    public subscribeToSearchCriteria(): Observable<any> {
        return this.searchCriteria.asObservable();
    }

    public sendUrlParams(urlParams: MapQueryParams) {
        this.urlParamsSubject.next(urlParams);
    }

    public subscribeToUrlParams(): Observable<MapQueryParams> {
        return this.urlParamsSubject.asObservable();
    }

    public clearSearchMenu(): void {
        this.clearSerachMenuSubject.next(null);
    }

    public subscribeToClearSearchMenu(): Observable<any> {
        return this.clearSerachMenuSubject.asObservable();
    }

    public storePublicCommentsFlag(flag: boolean): void {
        localStorage.setItem("PUBLIC_COMMENTS_FLAG", flag.toString());
    }
    public retrievePublicCommentsFlag(): boolean {
        let flag: string = localStorage.getItem("PUBLIC_COMMENTS_FLAG");
        
        if(flag) {
            return localStorage.getItem("PUBLIC_COMMENTS_FLAG") == "true";
        } else {
            return true;
        }
    }

    public searchByText(text: string, noOfComments: number, noOfCollections: number, noOfAddresses: number): Observable<Array<SearchResult>> {

        let searchCriteria: any = {
            searchPhrase: text,
            sessionToken: this.authService.getUserId(),
            noOfPois: noOfComments,
            noOfCollections: noOfCollections,
            noOfPlaces: noOfAddresses
        }

        return this.authHttp.post<Array<SearchResult>>("rest/search/all", searchCriteria)
        .pipe(
            map(res => {
                return res;
            }))
    }

    public searchByCollectionId(text: string, collectionId: number, noOfPois: number): Observable<Array<SearchResult>> {
        let searchCriteria: any = {
            searchPhrase: text,
            sessionToken: this.authService.getUserId(),
            noOfPois: noOfPois,
        }
        return this.authHttp.post<Array<SearchResult>>(`rest/search/all?collectionId=${collectionId}`, searchCriteria)
        .pipe(
            map(res => {
                return res;
            }))
    }

    public getPlaceDetails(placeId: string): Observable<SearchResult> {
        return this.authHttp.get("rest/search/place/" + placeId)
        .pipe(
            map(res => {
                return res;
            }),
            catchError(this.handleError))
    }

    public reverseGeocoding(lat: number, lng: number): Observable<Array<string>> {
        return this.authHttp.get("rest/search/address/" + lat + "/" + lng)
        .pipe(
            map(res => {
                return res;
            }),
            catchError(this.handleError))
    }

    private handleError(error: any): Observable<any> {
        return throwError("Something went wrong, please try again later." + error);
    }
}
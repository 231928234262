import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SvgIconName } from './svg-icon-name.enum';

@Component({
  selector: 'shout-svg-icons',
  templateUrl: './svg-icons.component.html',
  styleUrls: ['./svg-icons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SvgIconsComponent {

  @Input() name: SvgIconName;

  @Input() width?: string;

  @Input() height?: string;

  @Input() size?: string;

  SvgIconName = SvgIconName;
}

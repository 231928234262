import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { MODAL_COMPONENTS_NAME } from 'app_code/app/ui/modals/services/modal-components-list';
import { ModalsService } from 'app_code/app/ui/modals/services/modals.service';

@Injectable({ providedIn: 'root' })
export class PasswordResetGuard  {
    constructor(private modalsService: ModalsService) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    if (route?.queryParams['id']) {
        this.modalsService.open(MODAL_COMPONENTS_NAME.PasswordResetModal);
    }
    return true;

  }
}
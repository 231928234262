import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthService } from '../../shared/services/auth/auth.service';
import { ModalsService } from 'app_code/app/ui/modals/services/modals.service';
import { MODAL_COMPONENTS_NAME } from 'app_code/app/ui/modals/services/modal-components-list';

@Injectable()
export class AuthGuard  {

constructor(private authService: AuthService,
            private modalService: ModalsService) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    this.authService.redirectUrl = null;
    this.authService.redirectParams = null;
    if(this.authService.isJwtValid(null)) {
      if(!this.authService.isAccountActive()) {
        const root = route.root.firstChild.url;
        console.log("CURRENT ROOT: " + root); // Will log the current path
        this.modalService.open(MODAL_COMPONENTS_NAME.LoginModal);
        this.authService.redirectUrl = state.url.split("?")[0];
        this.authService.redirectParams = route.queryParamMap;
        return false;
      }
      return true;
    }
    this.modalService.open(MODAL_COMPONENTS_NAME.LoginModal);
    return false;
  }
}

import { Injectable, Compiler, Injector, NgModuleFactory, ComponentRef, NgModuleRef, ApplicationRef } from '@angular/core';
import { MODAL_MODULE_NAME_TO_REAL_MODULE_IMPORT_FN } from './modal-components-list';
import { Observable, Subject } from 'rxjs';
import { ModalCloseStatus } from './modal-close-status';

@Injectable({
  providedIn: 'root'
})
export class ModalsService {

  private modalComponentRef: ComponentRef<any>;
  private modalModuleRef: NgModuleRef<any>;
  private name: string;

  private modalCloseStatus$ = new Subject<unknown>();

  get modalCloseStatus(): Observable<unknown> {
    return this.modalCloseStatus$;
  }

  constructor(private appRef: ApplicationRef, private injector: Injector, private compiler: Compiler) {}

  async open<T>(name: string, input?: unknown): Promise<void> {
    if (this.name) {
      return;
    }
    this.name = name;
    const moduleCreationFn = MODAL_MODULE_NAME_TO_REAL_MODULE_IMPORT_FN[name];
  
    if (!moduleCreationFn) {
      throw new Error(`Module or Component type for key "${name}" not found.`);
    }

    // Load the module
    const moduleFactoryOrModule = await moduleCreationFn();
    let moduleFactory;
    if (moduleFactoryOrModule instanceof NgModuleFactory) {
      moduleFactory = moduleFactoryOrModule; // AOT
    } else {
      moduleFactory = await this.compiler.compileModuleAsync(moduleFactoryOrModule); // JIT
    }
    this.modalModuleRef = moduleFactory.create(this.injector);

    // Create the component
    const component = moduleFactoryOrModule.entry ? moduleFactoryOrModule.entry : moduleFactoryOrModule.moduleType.entry; // get component to be loaded from entry property in module
    const componentFactory = this.modalModuleRef.componentFactoryResolver.resolveComponentFactory(component);
    this.modalComponentRef = componentFactory.create(this.modalModuleRef.injector);
    if (input) {
      this.modalComponentRef.instance.input = input;
    }

    // Attach the view so that Angular recognizes it for change detection
    this.appRef.attachView(this.modalComponentRef.hostView);
    document.body.appendChild(this.modalComponentRef.location.nativeElement);
  }

  close(output?: ModalCloseStatus) {
    if (this.modalComponentRef) {
      this.modalComponentRef.destroy();
    }

    if (this.modalModuleRef) {
      this.modalModuleRef.destroy();
    }

    this.modalCloseStatus$.next(output ? output : null);
  
    this.name = null;
  }
}

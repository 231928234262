import { NgModule } from '@angular/core';
import { SpaceshoutButtonComponent } from './components/button/spaceshout-button.component';
import { SvgIconsComponent } from './components/svg-icons/svg-icons.component';
import { TranslateModule } from '@ngx-translate/core';
import { LeftSideBarComponent } from './components/nav/left-side-bar/left-side-bar.component';
import { SpaceshoutInputComponent } from './components/input/spaceshout-input.component';
import { CommonModule } from '@angular/common';
import { SpaceshoutTextButtonComponent } from './components/text-button/spaceshout-text-button.component';
import { SpaceShoutCheckboxComponent } from './components/checkbox/space-shout-checkbox.component';
import { SpaceShoutModalComponent } from './components/modal/space-shout-modal.component';
import { SpaceShoutToastComponent } from './components/toast/space-shout-toast.component';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { ResizeListenerDirective } from './directives/resize.directive';
import { SpaceshoutTextAreaComponent } from './components/text-area/spaceshout-text-area.component';
import { ImagePreviewDirective } from './directives/imagePreview.directive';
import { SpaceShoutDropdownComponent } from './components/dropdown/space-shout-dropdown.component';
import { SpaceShoutToggleComponent } from './components/toggle/space-shout-toggle.component';
import { SpaceShoutSegmentedButtonComponent } from './components/segmented-button/space-shout-segmented-button.component';
import { LottieComponent } from 'ngx-lottie';
import { FullScreenImageComponent } from './components/full-screen-image/full-screen-image.component';
import { ExifConfirmComponent } from './components/exif-confirm/exif-confirm.component';
import { ImageGalleryComponent } from './components/image-gallery/image-gallery.component';
import { ImageCarouselComponent } from './components/image-carousel/image-carousel.component';
import { ImageLightboxComponent } from './components/image-lightbox/image-lightbox.component';
import { FileUploadModule } from 'ng2-file-upload';
import { CdkDrag, CdkDragPlaceholder, CdkDropList } from '@angular/cdk/drag-drop';
import { AutoFocusDirective } from './directives/auto-focus.directive';
import { SpaceshoutIconButtonComponent } from './components/icon-button/spaceshout-icon-button.component';

@NgModule({
    declarations: [
        SpaceshoutButtonComponent,
        SpaceshoutIconButtonComponent,
        SvgIconsComponent,
        LeftSideBarComponent,
        SpaceshoutInputComponent,
        SpaceshoutTextButtonComponent,
        SpaceShoutCheckboxComponent,
        SpaceShoutModalComponent,
        SpaceShoutToastComponent,
        SpaceshoutTextAreaComponent,
        SpaceShoutDropdownComponent,
        SpaceShoutToggleComponent,
        SpaceShoutSegmentedButtonComponent,
        FullScreenImageComponent,
        ExifConfirmComponent,
        ImageGalleryComponent,
        ImageCarouselComponent,
        ImageLightboxComponent,

        ClickOutsideDirective,
        ResizeListenerDirective,
        ImagePreviewDirective,
        AutoFocusDirective
    ],
    imports: [
        CommonModule,
        TranslateModule,
        LottieComponent,
        FileUploadModule,
        CdkDropList,
        CdkDrag,
        CdkDragPlaceholder
    ],
    exports: [
        SpaceshoutButtonComponent,
        SpaceshoutIconButtonComponent,
        SvgIconsComponent,
        LeftSideBarComponent,
        SpaceshoutInputComponent,
        SpaceshoutTextButtonComponent,
        SpaceShoutCheckboxComponent,
        SpaceShoutModalComponent,
        SpaceShoutToastComponent,
        SpaceshoutTextAreaComponent,
        SpaceShoutDropdownComponent,
        SpaceShoutToggleComponent,
        SpaceShoutSegmentedButtonComponent,
        FullScreenImageComponent,
        ExifConfirmComponent,
        ImageGalleryComponent,
        ImageCarouselComponent,
        ImageLightboxComponent,

        ClickOutsideDirective,
        ResizeListenerDirective,
        ImagePreviewDirective,
        AutoFocusDirective
    ],
    providers: []
})
export class SharedModule { }

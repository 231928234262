
@if (isWelcome) {
  <div class="welcome" [ngClass]="{'welcome--text': !point.poi.image, 'welcome--logged-user': isLoggedIn}">
      <div class="welcome__wrapper">
        <spaceshout-button
          class="welcome__wrapper__close"
          [priority]="'senary'"
          [size]="'roundedSmall'"
          [icon]="'closeCircleFilled'"
          (clicked)="onClose()"
        >
        </spaceshout-button>

        <div class="welcome__wrapper__container">
          <shout-svg-icons class="welcome__wrapper__container__logo" [name]="'spaceshoutLogo'"></shout-svg-icons>

          <h1>{{'map.welcome.title' | translate}}</h1>
          <h2>{{ 'map.welcome.sub_title' | translate: { name: point.title } }}</h2>
          <div class="welcome__wrapper__container__subtitle">
            <p>{{'map.welcome.sub_title2' | translate}}</p>
            <p>{{'map.welcome.sub_title3' | translate}}</p>
            <p>...</p>
          </div>

          <div class="welcome__wrapper__container__cta">
            <spaceshout-button
              *ngIf="!isLoggedIn"
              [type]="'submit'"
              [label]="'map.welcome.signUpBtnLabel' | translate"
              (clicked)="onSignUp()"
            >
            </spaceshout-button>
            <spaceshout-button
                *ngIf="!isLoggedIn"
                [priority]="'secondary'"
                [label]="'map.welcome.signInBtnLabel' | translate"
                (clicked)="onSignIn()"
            >
            </spaceshout-button>
          
          </div>

          <div class="welcome__wrapper__container__external-methods">
            <img src="assets/appstore.webp" alt="Download app from app store" (click)="onGetFromAppStore()">
            <img src="assets/googleplay.webp" alt="Download app from google play store" (click)="onGetFromGooglePlay()">
          </div>
        </div>
      </div>
    <div class="welcome__overlay"></div>
  </div>
}

@if (point?.poi?.images?.length > 0) {
  <shout-point-with-image
    [point]="point"
    [blockEvents]="mapService.blockClickEventsOnPoints | async"
    [searchType]="searchType"
    [hasSelectedItem]="hasSelectedItem"
    [isCollectionHintVisible]="mapService.isCollectionHintVisible"
    [image_path]="IMAGE_PATH"
    [showMinimalisticVersion]="storeDataService.viewPointsPanelToggle | async"
    (clicked)="onPointClick()"
    (wheeled)="onWheel($event)"
  ></shout-point-with-image>
} @else {
  <shout-text-point
    [point]="point"
    [blockEvents]="mapService.blockClickEventsOnPoints | async"
    [searchType]="searchType"
    [hasSelectedItem]="hasSelectedItem"
    [isCollectionHintVisible]="mapService.isCollectionHintVisible"
    [isWelcome]="isWelcome"
    [description]="description"
    [showMinimalisticVersion]="storeDataService.viewPointsPanelToggle | async"
    (clicked)="onPointClick()"
    (wheeled)="onWheel($event)"
  ></shout-text-point>
}

import { Log } from 'ng2-logger/browser';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { JwtToken } from 'app_code/app/shared/interfaces/jwt-token';


@Injectable()
export class UserUpdateService {

  private log = Log.create('UserUpdateService');

    constructor(private authHttp: HttpClient) { }

    public checkIfUsernameIsAvailableForUpdate(username: string): Observable<any> {
        return this.authHttp.get("rest/user/check/update/username/" + username)
            .pipe(
                map(res => {
                    return res;
                }),
                catchError(this.handleError)
            );
    }

    public updateUsername(username: string): Observable<any> {
        const updateUsernameWS = {
            username: username
        }
        return this.authHttp.put("rest/user/username", updateUsernameWS)
            .pipe(
                map(res => {
                    return "ok";
                }),
                catchError(this.handleError)
            );
    }

    public checkIfEmailIsAvailable(email: string): Observable<any> {
        return this.authHttp.get("rest/user/check/email/" + email)
            .pipe(
                map(res => {
                    return res;
                }),
                catchError(this.handleError)
            );
    }

    public updateEmail(email: string, password: string): Observable<any> {
        const emailUpdateRequest = {
            email: email,
            password: password
        }
        return this.authHttp.post("rest/user/initiate/email", emailUpdateRequest)
            .pipe(
                map(res => {
                    return "ok";
                }),
                catchError(this.handleError)
            )
    }

    public updatePassword(oldPassword: string, newPassword: string): Observable<any> {
        const passwordUpdateRequest = {
            oldPassword: oldPassword,
            newPassword: newPassword
        }
        return this.authHttp.put("rest/user/password", passwordUpdateRequest)
            .pipe(
                map((res : JwtToken) => {return res;}),
                catchError((e: any) => {
                    if (e.status === 400) {
                        return throwError("Email is already in use. Please log in or reset you password.");
                    } else {
                        return throwError("Something went wrong, please try again later.");
                    }
                }))
    }


    private handleError(error: any): Observable<any> {
        return throwError("Something went wrong, please try again later." + error);
    }

}

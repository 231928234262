import { Subject } from 'rxjs';
import { Log } from 'ng2-logger/browser';
import { Injectable, HostListener } from '@angular/core';
/*
* Utility service for detecting window resize event
*/
@Injectable()
export class WindowResizeService {

    private log = Log.create("WindowResizeService");
    public windowResized = new Subject<Event>();
    private scrollBarWidth:number = null;

    constructor() {
        window.addEventListener('resize', (event) => {
            this.log.data("window resized: ",  window.innerWidth);
            this.scrollBarWidth = this.getScrollbarWidth()
            this.windowResized.next(event);
        });
    }

    public getScrollbarWidth() {

        if(this.scrollBarWidth) {
            return this.scrollBarWidth;
        }

        let inner = document.createElement('p');
        inner.style.width = "100%";
        inner.style.height = "200px";
      
        var outer = document.createElement('div');
        outer.style.position = "absolute";
        outer.style.top = "0px";
        outer.style.left = "0px";
        outer.style.visibility = "hidden";
        outer.style.width = "200px";
        outer.style.height = "150px";
        outer.style.overflow = "hidden";
        outer.appendChild(inner);
      
        document.body.appendChild(outer);
        var w1 = inner.offsetWidth;
        outer.style.overflow = 'scroll';
        var w2 = inner.offsetWidth;
      
        if (w1 == w2) {
          w2 = outer.clientWidth;
        }
      
        document.body.removeChild(outer);
      
        this.scrollBarWidth = (w1 - w2);
        return this.scrollBarWidth
      }
}
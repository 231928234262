import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Params, RouterStateSnapshot } from '@angular/router';
import { MetaTagUpdateService } from 'app_code/app/shared/services/meta-tag-update/meta-tag-update.service';
import { MapQueryParmsInitializer } from 'app_code/app/ui/map/model/map-query-params-initializer';
import { MapQueryParams } from 'app_code/app/ui/map/model/map-query-params-model';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MapParamsResolver  {
    constructor(private metaTagUpdateService: MetaTagUpdateService) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<MapQueryParams> | Promise<MapQueryParams> | MapQueryParams {
        if (!route?.queryParams) {
            return null;
        }
        this.metaTagUpdateService.init(route?.queryParams?.collectionId);
        return this.getUrlParams(route.queryParams);
    }

    private getUrlParams(params: Params): MapQueryParams {
        return {
            mapInitializer: this.getMapInitializerBasedOnQueryParams(params),
            swLat: params.swLat ? +params.swLat as number : null,
            swLng: params.swLng ? +params.swLng as number : null,
            neLat: params.neLat ? +params.neLat as number : null,
            neLng: params.neLng ? +params.neLng as number : null,
            commentId: params.commentId ? +params.commentId as number : null,
            collectionId: params.collectionId ? +params.collectionId as number : null,
            withoutPopup: params.withoutPopup ? !!params.withoutPopup as boolean : null,
        };
    }

    private getMapInitializerBasedOnQueryParams(params: Params): MapQueryParmsInitializer {
        const hasBounds = this.hasBound(params);
        
        if (params.commentId) {
            return MapQueryParmsInitializer.COMMENT_ID;
        } else if(params.collectionId) {
            return MapQueryParmsInitializer.COLLECTION_ID;
        } else if(hasBounds) {
            return MapQueryParmsInitializer.BOUNDS;
        }
        return MapQueryParmsInitializer.NONE;
    }

    public hasBound(params: Params) : boolean {
        return params?.swLat && params?.swLng && params?.neLat && params?.neLng;
    }
}

import { Pageable } from '../../interfaces/pageable';
import { Log } from 'ng2-logger/browser';
import { HttpClient } from '@angular/common/http';
import { Hashtag } from '../../interfaces/hashtag';
import { User } from '../../interfaces/user';
import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { JwtToken } from 'app_code/app/shared/interfaces/jwt-token';


@Injectable()
export class UserService {

  private log = Log.create('UserService');

    constructor(private authHttp: HttpClient) { }

    public getUserById(id: string): Observable<User> {
        return this.authHttp.get("rest/user/basic/" + id)
        .pipe(
            map(res => {
                return res;
            }),
            catchError(this.handleError))
    }

    public getUser(id: string): Observable<User> {
        return this.authHttp.get("rest/friend/" + id)
        .pipe(
            map(res => {
                return res;
            }),
            catchError(this.handleError))
    }

    public getFriendshipStatus(id: string):  Observable<string> {
        return this.authHttp.get("rest/friendship/status/" + id)
        .pipe(
            map(res => {
                return res;
            }),
            catchError(this.handleError))
    }

    public getFriends(userId: string, page: number, pageSize: number): Observable<any> {
        return this.authHttp.get("rest/friends/" + userId + "?page=" + page + "&size=" + pageSize)
        .pipe(
            map(res => {
                return res;
            }),
            catchError(this.handleError))
    }

    public getPendingFriends(page: number, pageSize: number): Observable<any> {
        return this.authHttp.get("rest/friendship/received"+ "?page=" + page + "&size=" + pageSize)
        .pipe(
            map(res => {
                return res;
            }),
            catchError(this.handleError))
    }

    public sendFriendshipRequest(id: string): Observable<string> {
        return this.authHttp.post("rest/friendship/request/" + id, null)
        .pipe(
            map(res => {
                return res;
            }),
            catchError(this.handleError))
    }

    public acceptFriendshipRequest(id: string): Observable<string> {
        return this.authHttp.put("rest/friendship/accept/" + id, null)
        .pipe(
            map(res => {
                return res;
            }),
            catchError(this.handleError))
    }

    public declineFriendshipRequest(id: string): Observable<string> {
        return this.authHttp.delete("rest/friendship/decline/" + id)
        .pipe(
            map(res => {
                return res;
            }),
            catchError(this.handleError))
    }

    public cancelFriendshipRequest(id:string): Observable<string> {
        return this.authHttp.delete("rest/friendship/cancel/" + id)
        .pipe(
            map(res => {
                return res;
            }),
            catchError(this.handleError))
    }

    public unfriend(id: string): Observable<string> {
        return this.authHttp.delete("rest/friendship/" + id)
        .pipe(
            map(res => {
                return res;
            }),
            catchError(this.handleError))
    }

    public updateHashtags(hashtags: Hashtag[]) : Observable<void> {
        let setOfHashtags: string[] = [];
        hashtags.forEach(item => {
            setOfHashtags.push(item.text)
        })
        return this.authHttp.put("rest/user/hashtags", setOfHashtags)
        .pipe(
            map(res => {
                return "ok";
            }),
            catchError(this.handleError))
    }

    public updateProfileImage(image: string): Observable<void> {
        let imageObject: any = {
            image: image
        }
        return this.authHttp.put("rest/user/image/profile", imageObject)
        .pipe(
            map(res => {
                return "ok";
            }),
            catchError(this.handleError))
    }

    public updateBackgroundImage(image: string): Observable<void> {
        let imageObject: any = {
            image: image
        }
        return this.authHttp.put("rest/user/image/background", imageObject)
        .pipe(
            map(res => {
                return "ok";
            }),
            catchError(this.handleError))
    }

    public getUsersByUsernameText(text: string): Observable<any> {
        return this.authHttp.get("rest/user/username/" + text + "?size=10")
        .pipe(
            map(res => {
                return res;
            }),
            catchError(this.handleError))
    }

    public updateUsername(username: string): Observable<void> {
        return this.authHttp.put("rest/user/username/" + username, null)
        .pipe(
            map(res => {
                return "ok";
            }),
            catchError(this.handleError))
    }

    public blockUser(id: string, block: boolean): Observable<null> {
        if(block) {
        return this.authHttp.post("rest/user/block/" + id, null)
        .pipe(
            map(res => {
                return "ok";
            }),
            catchError(this.handleError))
        } else {
            return this.authHttp.delete("rest/user/unblock/" + id)
            .pipe(
                map(res => {
                    return "ok";
                }),
                catchError(this.handleError))
        }
    }

    public getBlockedUsers(): Observable<Pageable> {
        return this.authHttp.get("rest/friendships/blocked")
        .pipe(
            map(res => {
                return res;
            }),
            catchError(this.handleError))
    }

    public deleteAccount(password: string): Observable<JwtToken> {
        let passwordObject = {
            password: password
        }
        this.log.data("deleting account");
        return this.authHttp.post("rest/user/delete", passwordObject)
        .pipe(
            map(res => {
                return res;
            }),
            catchError(this.handleError)
        )
    }

    private handleError(error: any): Observable<any> {
        return throwError("Something went wrong, please try again later." + error);
    }

}

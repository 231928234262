import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { Router } from "@angular/router";
import { tap } from "rxjs/operators";
import { AuthService } from "./auth.service";
import { Injectable, NgZone } from "@angular/core";
import { Log } from 'ng2-logger/browser';
import { NOT_PROTECTED_URLS } from 'app_code/app/shared/constants';

@Injectable({providedIn: 'root'})
export class AuthInterceptor implements HttpInterceptor {

    private log = Log.create('AuthInterceptor');

    constructor(private router: Router, private authService: AuthService,
                private ngZone: NgZone) {}
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      return next.handle(req).pipe(
        tap((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
                
            }
          }, (err: any) => {
            if (err instanceof HttpErrorResponse) {
              if (err.status === 401) {
                this.ngZone.run(() => {
                  this.log.data('Unauthorized response returned: ', err.status);
                  this.authService.removeToken();
                  let protectedUrl: boolean = false;
                  NOT_PROTECTED_URLS.forEach(url => {
                    console.log("AUTH", window.location.protocol + "//" + window.location.host + "/" + url)
                    if(window.location.href.startsWith(window.location.protocol + "//" + window.location.host + "/" + url)) {
                      protectedUrl = true;
                    }
                  });
                  if(!protectedUrl) {
                    this.router.navigate(['/']);
                  }
                  
                })
              }
            }
          })
      
      ) 
    }
}
<button
    class="spaceshout-button" 
    [attr.name]="name ? name : ''"
    [attr.id]="name ? name : ''"
    [attr.priority]="priority" 
    [attr.size]="size"
    [attr.type]="type ? type : ''"
    [ngClass]="{'disabled': disabled, 'loading': loading}" 
    [title]="tooltip ? tooltip : ''"
    (click)="emitClickEvent($event)"
>
    @if (icon) {
        @if (hasIconLabel) {
            <div class="spaceshout-button--column">
                <shout-svg-icons [name]="icon" [size]="size"></shout-svg-icons>
                <ng-content select="[icon-label]"></ng-content>
            </div>
        } 
        @else {
            <shout-svg-icons [name]="icon" [size]="size"></shout-svg-icons>
        }
    }
    @else {
        {{label}}
    }

    @if (loading) {
        <ng-lottie [options]="options"></ng-lottie>
    }

</button>
<ng-content select="[content]"></ng-content>


import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'urlPipe'})
export class UrlPipe implements PipeTransform {
  transform(text: string, highlight: boolean): string {
    if (text) {
        
      let urlPattern = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;;
      if(highlight) {
        return text.replace(urlPattern, (match) => `<span class="hashtag">${match}</span>`);
      } else {
        return text.replace(urlPattern, (match) => `<a class="url" target="_blank" href="${match}">${match}</a>`);
      }
      
    } else {
      return text;
    }
  }
}
import { Log } from 'ng2-logger/browser';
import { catchError, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError, Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class HashtagService {

    private log = Log.create('HashtagService');
    public navigationHashtagSubject = new Subject<string>();

    constructor(private authHttp: HttpClient) { }

    public navigationHashtagSelected(hashtag: string): void {
        this.navigationHashtagSubject.next(hashtag);
    }

    public getHashtagsByText(text: string) : Observable<any> {
        return this.authHttp.get("rest/comment/hashtag/" + text + "?size=20")
        .pipe(
            map(res => {
                return res;
            }), 
            catchError(this.handleError))
    }

    private handleError(error: any): Observable<any> {
        this.log.data(error);
        return throwError(error.message || 'Server error');
    }
}
import { ActivateAccountComponent } from './../ui/activate-account/activate-account.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './route-guards/auth-guard.service';
import { AdminGuard } from './route-guards/admin-guard.service';
import { PasswordResetGuard } from './route-guards/password-reset-guard.service';
import { MapParamsResolver } from './resolvers/map-query-params.resolver';
import { APP_ROUTES } from './enums/app-routes';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('../ui/map/map.module').then(m => m.MapModule),
    canActivate: [PasswordResetGuard],
    resolve: { mapParams: MapParamsResolver},
  },
  {
    path: APP_ROUTES.SUBSCRIBED_AREAS,
    loadChildren: () => import('../ui/subscribed-areas/subscribed-areas.module').then(m => m.SubscribedAreasModule),
    canActivate: [AuthGuard]
  },
  {
    path: APP_ROUTES.COLLECTIONS,
    loadChildren: () => import('../ui/collections/collections-page/collections.module').then(m => m.CollectionsModule),
    canActivate: [AuthGuard]
  },
  {
    path: APP_ROUTES.TERMS_OF_SERVICE,
    loadChildren: () => import('../ui/policies/terms-of-service/terms-of-service.module').then(m => m.TermsOfServiceModule),
  },
  {
    path: APP_ROUTES.PRIVACY,
    loadChildren: () => import('../ui/policies/privacy/privacy.module').then(m => m.PrivacyModule),
  },
  {
    path: APP_ROUTES.ACIVATE,
    component: ActivateAccountComponent
  },
  {
    path: APP_ROUTES.ADMIN,
    loadChildren: () => import('../ui/admin/admin.module').then(m => m.AdminModule),
    canActivate: [AdminGuard]
  },
  {
    path: '', 
    redirectTo: '/', 
    pathMatch: 'full' 
  },
  {
    path: '**',
    redirectTo: '', 
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Injectable } from '@angular/core';
import { environment } from 'app_code/environments/environment';
import { Analytics, getAnalytics, logEvent } from 'firebase/analytics';

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  private analytics: Analytics = null;

  constructor() {
    if (environment.firebase) {
      this.analytics = getAnalytics();
    }
  }

  public logEvent(eventName: string, eventParams?: { [key: string]: any }) {
    if (this.analytics) {
      logEvent(this.analytics, eventName, eventParams);
    }
  }
}

import { Subject, timer } from 'rxjs';
import { Log } from 'ng2-logger/browser';
import { AuthService } from './shared/services/auth/auth.service';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MixpanelService } from './shared/services/login/mixpanel.service';
import { take, filter, takeUntil} from 'rxjs/operators';
import { StoreDataService } from './shared/services/store/store-data.service';
import { AnimationOptions } from 'ngx-lottie';
import { NavigationEnd, Router } from '@angular/router';
import { INITIAL_DEFAULT_LOADING_TIME } from './shared/constants';
import { TranslateService } from '@ngx-translate/core';
import { ModalsService } from './ui/modals/services/modals.service';
import { MODAL_COMPONENTS_NAME } from './ui/modals/services/modal-components-list';
import { LocalStorageService } from './shared/services/local-storage/local-storage.service';
import { LocalStorageKey } from './shared/model/local-storage-key';
import { APP_ROUTES } from './routing/enums/app-routes';

@Component({
  selector: 'shout-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush // TO-DO - enable it after legacy profile and wall routes will be rewritten
})
export class AppComponent implements OnInit, OnDestroy {

  isReady = false;
  animationOptions: AnimationOptions = {
    path: 'assets/animations/sloader.json',
  };

  private log = Log.create('AppComponent');
  private destroy$ = new Subject<void>();

  constructor(
    private authService: AuthService,
    private mixpanel: MixpanelService,
    private storeDataService: StoreDataService,
    private router: Router,
    private cd: ChangeDetectorRef,
    private translate: TranslateService,
    private modalsService: ModalsService,
    private localStorageService: LocalStorageService
  ) {
  }

  ngOnInit() {
    document.documentElement.lang = this.translate.currentLang;
    this.mixpanel.init();
    this.authService.loginOnStartup()
    this.hideLoaderWhenAppReady();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private hideLoaderWhenAppReady(): void {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd && !this.isReady),
      takeUntil(this.destroy$)
    ).subscribe((event: NavigationEnd) => {
      if (event.url === '/' || event.url.startsWith('/?')) { // Initial map loading, wait for all markers to be in place
        this.storeDataService.mapInitialMarkersLoadCompleted.pipe(
          takeUntil(this.destroy$)
        ).subscribe(_ => {
          this.showLandingModalIfNeeded();
          this.isReady = true;
          this.cd.detectChanges();
        })
      } else { // default loader timeout for all other pages
        timer(INITIAL_DEFAULT_LOADING_TIME).pipe(
          take(1)
        ).subscribe(() => {
          this.showLandingModalIfNeeded();
          this.isReady = true;
          this.cd.detectChanges();
        })
      }
    });
  }

  private showLandingModalIfNeeded(): void {
    const wasBannerAlreadyShowed = this.localStorageService.getItem(LocalStorageKey.LANDING_MODAL_VISIBLE) ? true : false;
    if (!wasBannerAlreadyShowed && !this.authService.isAccountActive() && !this.isRouteBlocked() && !this.storeDataService.isEmbedded.value) {
      this.modalsService.open(MODAL_COMPONENTS_NAME.LandingModal);
    }
  }

  private isRouteBlocked(): boolean {
    const protectedRoutes = [
      APP_ROUTES.PRIVACY,
      APP_ROUTES.TERMS_OF_SERVICE,
      APP_ROUTES.ADMIN
    ];
    return !!protectedRoutes.find(route => this.router.url.includes(route));
  }
}

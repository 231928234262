
export interface SearchOpenBy {
    trigger: SearchOpenTrigger;
    value?: string;
}

export enum SearchOpenTrigger {
    MAP,
    ADD_POINT_PANEL,
    EDIT_POINT_PANEL
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'tagPipe'})
export class TagPipe implements PipeTransform {
  transform(text: string, search): string {
    if (text) {
      let hashtagPattern = /(#\w+) ?/g;

      return text.replace(hashtagPattern, (match) => `<span class="hashtag">${match}</span>`);
    } else {
      return text;
    }
  }
}
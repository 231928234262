import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'emailPipe'})
export class EmailPipe implements PipeTransform {
  transform(text: string, highlight: boolean): string {
    if (text) {
      
      let emailPattern = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi;
      
      if(highlight) {
        return text.replace(emailPattern, (match) => `<span class="hashtag">${match}</span>`);
      } else {
        return text.replace(emailPattern, (match) => `<a class="email" href="mailto:${match}">${match}</a>`);
      }
      
    } else {
      return text;
    }
  }
}
import { Log } from 'ng2-logger/browser';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Injectable, NgZone } from '@angular/core';
import { MapQueryParmsInitializer } from 'app_code/app/ui/map/model/map-query-params-initializer';
import { MapQueryParams } from 'app_code/app/ui/map/model/map-query-params-model';

declare var $: any;
/*
* Utility service for reading/updating current URL
*/
@Injectable()
export class UrlManagerService {

    private log = Log.create("UrlManagerService");
    private previousUrl: string;
    private currentUrl: string;

    constructor(private router: Router, 
                private activatedRoute: ActivatedRoute,
                private ngZone: NgZone) {
                    this.subscribeToCurrentRoute();
    }

    private subscribeToCurrentRoute(): void {
        this.currentUrl = this.router.url;
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.previousUrl = this.currentUrl;
                this.currentUrl = event.url;
            };
        });
    }

    public getPreviousUrl() {
        return this.previousUrl;
    }

    public getCurrentUrl() {
        return this.currentUrl;
    }    

    public getStringParamFromUrl(param: string) : string {
        let result : string = this.activatedRoute.snapshot.queryParamMap.get(param);
        this.log.data("param from URL '" + param + "': ", result);
        if(result) {
            return result;
        }
        return result;
    }

    public getNumberParamFromUrl(param: string) : number {
        let result = this.getStringParamFromUrl(param);
        if(result) {
            return +result;
        }
        return null;
    }

    public getBooleanParamFromUrl(param: string) : boolean {
        let result = this.getStringParamFromUrl(param);
        return result =="true";
    }

    public updateCommentIdInURL(urlParams: MapQueryParams): void {
        this.ngZone.run(() => {
            this.router.navigate([], {
                relativeTo: this.activatedRoute,
                queryParams: urlParams
                // queryParams: {
                // // ...this.activatedRoute.snapshot.queryParams,
                // commentId: id
                // }
            });
        });
    }

    public getUrlParams() : MapQueryParams {

        let urlParams: MapQueryParams = {}

        urlParams.swLat = this.getNumberParamFromUrl("swLat"),
        urlParams.swLng = this.getNumberParamFromUrl("swLng"),
        urlParams.neLat = this.getNumberParamFromUrl("neLat"),
        urlParams. neLng = this.getNumberParamFromUrl("neLng")
        urlParams.commentId = this.getNumberParamFromUrl("commentId");
        urlParams.collectionId = this.getNumberParamFromUrl("collectionId");
        urlParams.withoutPopup = this.getBooleanParamFromUrl("withoutPopup");

        return urlParams;
    }

    public findParamsCombination(urlParams: MapQueryParams): MapQueryParmsInitializer {

        let hasBounds: boolean = this.hasBound(urlParams);
        
        if(urlParams.commentId) {
            return MapQueryParmsInitializer.COMMENT_ID;
        }
        else if(urlParams.collectionId) {
            return MapQueryParmsInitializer.COLLECTION_ID;
        }
        else if(hasBounds) {
            return MapQueryParmsInitializer.BOUNDS;
        } 
        else {
            return MapQueryParmsInitializer.NONE;
        }
    }

    public hasBound(urlParams: MapQueryParams) : boolean {
        if(urlParams && urlParams.swLat && urlParams.swLng && urlParams.neLat && urlParams.neLng) {
            return true;
        } 
        else {
            return false;
        }
    }

    public generateUrl(urlParams: MapQueryParams) : string {

        let queryString: string = Object.keys(urlParams)
        .filter((value, index) => urlParams[value] != null )
        .map(key => {
            if(urlParams[key]) {
                return key + '=' + urlParams[key];
            }
        }).join('&');

        if(queryString) {
            return this.getDomain() + "?" + queryString;
        } 

        return this.getDomain();
    }

    public getDomain() : string {
        return window.location.origin + window.location.pathname;
    }
}
import { AccountStatus } from "./account-status";

export class User {
    public id : string;
    public username: string;
    public background: string;
    public profileImage: string;
    public email: string;
    public password: string;
    public friendshipStatus: string;
    public accStatus: AccountStatus;
    public emailConfirmed: boolean;
    public roles: string[];
    public noOfFriends: number;
    public noOfPending: number;

    public isAccountActive(): boolean {
        return this.accStatus === AccountStatus.ACTIVE
    }
}

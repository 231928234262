import { ChatMessage } from 'app_code/app/shared/interfaces/chat-message';
import { Chat } from '../../interfaces/chat';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError, Subject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Pageable } from 'app_code/app/shared/interfaces/pageable';

@Injectable()
export class ChatService {

    public messageReceived = new Subject<Chat>();
    public chatDeleted = new Subject<number>();
    public newConversationStarted = new Subject<Chat>();
    public currentDeviceType: string = 'desktop';

    constructor(private authHttp: HttpClient) { }

    public sendReceivedMessage(chat: Chat): void  {
        this.messageReceived.next(chat);
    }

    public sendDeletedChatId(id: number): void {
        this.chatDeleted.next(id);
    }

    public sendNewConversation(chat: Chat): void {
        this.newConversationStarted.next(chat);
    }

    public getNewConversationSubject(): Observable<Chat> {
        return this.newConversationStarted.asObservable();
    }

    public getChatByUserId(recipient: number): Observable<Chat> {
        return this.authHttp.get("rest/chat/" + recipient)
            .pipe(
                map(res => {
                    return res;
                }),
                catchError(this.handleError))
    }

    public getChatsByDate(date: number, page: number, size: number): Observable<Pageable> {
        return this.authHttp.get("rest/chats" + "?date=" + date + "&page=" + page + "&size=" + size)
            .pipe(
                map(res => {
                    return res;
                }),
                catchError(this.handleError))
    }

    public getMessagesByDate(chatId: number, date :number, page: number, size: number): Observable<Pageable> {
        let chatQuery: any = {
            chatId: chatId,
            date: date
        };
        return this.authHttp.post("rest/chat/messages?page=" + page + "&size=" + size, chatQuery)
            .pipe(
                map(res => {
                    return res;
                }),
                catchError(this.handleError))
    }

    public removeMessageById(message: number) {
        return this.authHttp.delete("rest/chat/messages/" + message)
            .pipe(
                map(res => {
                    return res;
                }),
                catchError(this.handleError))
    }

    public removeChatById(chat: number) {
        return this.authHttp.delete("rest/chats/" + chat)
            .pipe(
                map(res => {
                    return res;
                }),
                catchError(this.handleError))
    }

    public updateChats(original: Array<Chat> , merge: Chat): void {
        let chatsIndexToReplace = original.findIndex((chat) => { return chat.chatUsers[0].id === merge.chatUsers[0].id });
        if(chatsIndexToReplace > -1) {
            original[chatsIndexToReplace] = merge;
        } else {
            original.push(merge);
        }
    }

    public sortChatsByTimeCreated(original: Array<Chat>): void  {
            original.sort((a, b) => {
                if(a.message.created < b.message.created) {
                    return 1
                }
                if(a.message.created > b.message.created) {
                    return -1
                }
                return 0;
            })
    }

    public sortMessagesByTimeCreated(original: Array<ChatMessage>): void  {
            original.sort((a, b) => {
                if(a.created < b.created) {
                    return -1
                }
                if(a.created > b.created) {
                    return 1
                }
                return 0;
            })
    }

    /**
     * Returns string with name of class that should be applied
     * to a message displayed in the list of messages in chat.
     * This class makes borders of the message bubble rounded depending on it's position.
     */
    public getMessageStyleClass(messages: Array<ChatMessage>, index: number, recipient: string, user: string): string {

        /**
         * Represents previous, middle and next message sender ID's
         */

        let previous: string;
        let middle: string;
        let next: string;

        /**
         * Checks if previous or next message exists
         */
        if( messages[index - 1] != null) {
          previous = messages[index - 1].sender;
        }
        else {
            previous = null
        }

        middle = messages[index + 0].sender;

        if( messages[index + 1] != null) {
          next = messages[index + 1].sender;
        }
        else {
            next = null
        }

        /**
         * Applies matching class
         */
        if( middle == recipient ) {
            if(previous != recipient && next == recipient) {
              return 'message__card--friend-top';
            }
            else if(previous == recipient && next == recipient) {
              return 'message__card--friend-middle';
            }
            else if(previous == recipient && next != recipient) {
              return 'message__card--friend-bottom';
            }
        } else if ( middle == user) {

            if(previous != user && next == user) {
              return 'message__card--user-top';
            }
            else if(previous == user && next == user) {
              return 'message__card--user-middle';
            }
            else if(previous == user && next != user) {
              return 'message__card--user-bottom';
            }
        }
    }

    public resetVariables(): void  {
        this.currentDeviceType = 'desktop';
    }

    private handleError(error: any): Observable<any> {
        return throwError(error.message || "Server error");
    }
}

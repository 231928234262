import { Injectable } from "@angular/core";
import { LocalStorageKey } from "../../model/local-storage-key";

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {

    getItem(key: LocalStorageKey): string {
        return localStorage.getItem(key);
    }

    setItem(key: LocalStorageKey, value: string): void {
        localStorage.setItem(key, value);
    }

    removeItem(key: LocalStorageKey): void {
        localStorage.removeItem(key);
    }
}

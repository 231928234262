export enum SpaceshoutPriority {
    primary = 'primary',
    secondary = 'secondary',
    tertiary = 'tertiary',
    quaternary = 'quaternary',
    quinary = 'quinary',

    brand= 'brand',
    danger = 'danger'
}
